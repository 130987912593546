/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-table::v-deep table {
  min-width: 1200px;
}
.service-table::v-deep table td.service-detail-col {
  min-width: 200px;
  font-size: 0.9em;
  vertical-align: top;
}
.drone-serial-no {
  display: block;
  font-size: 0.9em;
  margin-top: 2px;
}
.service-table-small {
  font-size: 0.9em;
}
