/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-status-select {
  min-width: 250px;
  max-width: 550px;
}
.active-services-chart {
  width: 25%;
}
.active-services-chart-panel {
  display: flex;
  flex-direction: 'row';
  justify-content: 'space-between';
  margin-left: 'auto';
  margin-right: 'auto';
}
